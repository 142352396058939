import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  OTP_REQUIRED,
  MFA_REQUIRED,
  SET_LATEST_VERSION
} from "../../constants/ActionTypes";
import axios from 'util/Api'

const _ = require('lodash');

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/register', {
      email: email,
      password: password,
      name: name
    }
    ).then(({ data }) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        console.log("payload: data.error", data.error);
        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({ email, password, otp }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('v1/auth/signIn', {
      email, password, otp
    }
    ).then(async ({ data }) => {
      if (data.success) {
        localStorage.setItem("token", JSON.stringify(data.data.token));
        localStorage.setItem("user", JSON.stringify(data.data.userData));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.token;

        dispatch({ type: OTP_REQUIRED, payload: false });
        dispatch({ type: MFA_REQUIRED, payload: false });
        dispatch({ type: USER_DATA, payload: data.data.userData });
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.data.token });

      } else {
        dispatch({ type: FETCH_ERROR, payload: data.data.message });
      }
    }).catch(function (error) {

      const otpRequired = _.get(error, 'response.data.data.otpRequired', null);
      const mfaRequired = _.get(error, 'response.data.data.mfaRequired', null);
      const errorMessage = _.get(error, 'response.data.data.message', 'Something went wrong!');

      if (otpRequired) {
        dispatch({ type: OTP_REQUIRED, payload: true });
        dispatch({ type: FETCH_ERROR, payload: errorMessage });
      }
      else if (mfaRequired) {
        dispatch({ type: MFA_REQUIRED, payload: true });
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: errorMessage });
        dispatch({ type: MFA_REQUIRED, payload: false });
        dispatch({ type: OTP_REQUIRED, payload: false });
      }

    });
  }
};

export const switchFirm = ({ firm_id }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('v1/auth/switchFirm', {
      firm_id
    }
    ).then(async ({ data }) => {
      if (data.success) {
        localStorage.setItem("token", JSON.stringify(data.data.token));
        localStorage.setItem("user", JSON.stringify(data.data.userData));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.token;
        dispatch({ type: OTP_REQUIRED, payload: false });
        dispatch({ type: USER_DATA, payload: data.data.userData });
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.data.token });
        window.location.href = "/";
      } else {
        dispatch({ type: FETCH_ERROR, payload: _.get(data, 'data.message', 'Something went wrong!') });
      }
    }).catch(function (error) {
      if (_.get(error, 'response.data.data.otpRequired', null)) {
        dispatch({ type: OTP_REQUIRED, payload: true });
      }
      dispatch({ type: FETCH_ERROR, payload: _.get(error, 'response.data.data.message', 'Something went wrong')});
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('v1/auth/me',
    ).then(({ data }) => {
      if (data.data) {
        localStorage.setItem("user", JSON.stringify(data.data));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};

export const setLatestVersion = (version) => {
  return (dispatch) => {
    dispatch({ type: SET_LATEST_VERSION, payload: version });
  }
}

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    }, 2000);
  }
};
