import React from "react";
import { Button, Checkbox, Form, Input, Alert } from "antd";
import { connect } from "react-redux";

import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import moment from "moment";
import TextLoop from 'react-text-loop';
var jwtDecode = require('jwt-decode');

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    console.log('component did update ', this.props.mfaRequired);
    if (this.props.mfaRequired) {
      if (!this.intervalFn) {
        this.startPolling();
      }
    }
    else if (this.props.token) {
      if (this.intervalFn) {
        clearInterval(this.intervalFn);
      }
      var decoded = jwtDecode(this.props.token);
      if (moment().unix() < decoded.exp) {
        this.props.history.push('/');
      }
    }
    else {
      if (this.intervalFn) {
        clearInterval(this.intervalFn);
      }
    }
  }

  startPolling() {
    this.intervalFn = setInterval(() => {
      console.log('interval call');
      this.props.form.validateFields(async (err, values) => {
        console.log('validate field ', err, values);
        if (!err) {
          this.props.userSignIn(values);
        }
      });
    }, 10000);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={require("assets/images/bg_image.png")} alt='Neature' />
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn" /></h1>
                <p>Sign In to access your account, files and much more!</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input type="password" placeholder="Password" />
                  )}
                </FormItem>
                {
                  this.props.otpRequired ? (
                    <FormItem>
                      {getFieldDecorator('otp', {
                        initialValue: "",
                        rules: [{ required: true, message: 'Please input OTP' }],
                      })(
                        <Input type="password" placeholder="OTP" />
                      )}
                    </FormItem>
                  ) : null
                }
                <FormItem
                  onClick={() => {
                    this.props.history.push('/forgotpassword');
                  }}
                >
                  <span className="gx-signup-form-forgot gx-link">Forgot Password?</span>
                </FormItem>
                {
                  this.props.mfaRequired ? (
                    <FormItem>
                      <Alert
                        banner
                        message={
                          <TextLoop mask>
                            <div>Please wait...</div>
                            <div>Waiting for admin to approve...</div>
                          </TextLoop>
                        }
                      />
                    </FormItem>
                  ) : null
                }
                <FormItem>
                  <Button disabled={this.props.mfaRequired} type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
                <h6>
                  By continuing, you're confirming that you have read our <span onClick={() => {
                    window.open('https://quickclientportal.com/billing-and-subscription-agreements/')
                  }} className="gx-signup-form-forgot gx-link">Billing & Subscription Agreement</span>, <span onClick={() => {
                    window.open('https://quickclientportal.com/terms-and-agreements/')
                  }} className="gx-signup-form-forgot gx-link">Terms & Agreements</span> and <span onClick={() => {
                    window.open('https://quickclientportal.com/privacy-policy/')
                  }} className="gx-signup-form-forgot gx-link">Privacy Policy</span>
                </h6>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { token, otpRequired, mfaRequired } = auth;
  return { token, otpRequired, mfaRequired }
};

export default connect(mapStateToProps, { userSignIn })(WrappedNormalLoginForm);
