import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon, Tooltip } from "antd";
import { Link } from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { userSignOut } from "../../appRedux/actions/Auth";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  HomeOutlined,
  UserOutlined,
  SnippetsOutlined,
  BuildOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
const jwtDecode = require('jwt-decode');

const MenuItemGroup = Menu.ItemGroup;


class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleMenuItemGroupClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-MenuItemGroup-popup";
    }
    return "";
  };


  handleOnIdle = event => {
    this.props.userSignOut();
  }

  handleOnActive = event => { }

  handleOnAction = (e) => { }

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const decoded = jwtDecode(this.props.token);
    console.log({ decoded });
    return (<Auxiliary>
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        timeout={1000 * 60 * 15}
        onActive={this.handleOnActive}
        onIdle={this.handleOnIdle}
        onAction={this.handleOnAction}
        debounce={250}
      />
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            inlineIndent={1}
            mode="inline">

            <MenuItemGroup key="home-group" className="gx-menu-group" title={'Home'}>
              {
                [2].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="home/dashboard">

                    <Link to="/home/dashboard">
                      <Icon style={{ fontSize: 20 }} style={{ fontSize: 20 }} type="dashboard" theme="outlined" />
                      <span>Dashboard</span>
                    </Link>

                  </Menu.Item>
                ) : null
              }
              {
                [4].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="home/clientDashboard">

                    <Link to="/home/clientDashboard">
                      <Icon style={{ fontSize: 20 }} style={{ fontSize: 20 }} type="dashboard" theme="outlined" />
                      <span>Dashboard</span>
                    </Link>

                  </Menu.Item>
                ) : null
              }
              <Menu.Item key="home/activity">

                <Link to="/home/activity">
                  <Icon style={{ fontSize: 20 }} style={{ fontSize: 20 }} type="clock-circle" theme="outlined" />
                  <span>Recent activities</span>
                </Link>

              </Menu.Item>
              {
                [2].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="home/mfa">

                    <Link to="/home/mfa">
                      <Icon style={{ fontSize: 20 }} type="property-safety" theme="outlined" />
                      <span>Staff Sign-in Requests</span>
                    </Link>

                  </Menu.Item>
                ) : null
              }
              <Menu.Item key="files/downloads">

                <Link to="/files/downloads">
                  <Icon style={{ fontSize: 20 }} type="cloud-download" theme="outlined" />
                  <span>Downloads</span>
                </Link>

              </Menu.Item>
            </MenuItemGroup>
            {
              [2, 3].indexOf(decoded.user_type) > -1 ? (
                <MenuItemGroup key="users-group" className="gx-menu-group" title={'Users'}>
                  {
                    [2].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="users/staffList">

                        <Link to="/users/staffList">
                          <Icon style={{ fontSize: 20 }} type="user" theme="outlined" />
                          <span>Staff</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                  <Menu.Item key="users/clientList">

                    <Link to="/users/clientList">
                      <Icon style={{ fontSize: 20 }} type="solution" theme="outlined" />
                      <span>Clients</span>
                    </Link>

                  </Menu.Item>
                  <Menu.Item key="users/groupList">

                    <Link to="/users/groupList">
                      <Icon style={{ fontSize: 20 }} type="team" theme="outlined" />
                      <span>Groups</span>
                    </Link>

                  </Menu.Item>
                </MenuItemGroup>
              ) : null
            }

            <MenuItemGroup key="files-group" className="gx-menu-group" title={'Documents'}>
              {
                [2, 4].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="files/private">

                    <Link to="/files/private">
                      <Icon style={{ fontSize: 20 }} type="safety-certificate" theme="outlined" />
                      <span>{decoded.user_type === 2 ? 'My private documents' : 'My documents'}</span>
                    </Link>

                  </Menu.Item>
                ) : null
              }
              {
                [2, 3].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="files/staff">

                    <Link to="/files/staff">
                      <Icon style={{ fontSize: 20 }} type="hdd" theme="outlined" />
                      <span>Firm documents</span>
                    </Link>

                  </Menu.Item>
                ) : null
              }

              {/* {
                [2, 3].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="files/client">

                      <Link to="/files/client">
                        <Icon style={{ fontSize: 20 }} type="file-done" theme="outlined" />
                        <span>Client documents</span>
                      </Link>
                    
                  </Menu.Item>
                ) : null
              } */}

              {
                [4].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="users/groupList">

                    <Link to="/users/groupList">
                      <Icon style={{ fontSize: 20 }} type="cluster" theme="outlined" />
                      <span>Group documents</span>
                    </Link>

                  </Menu.Item>
                ) : null
              }

              <Menu.Item key="files/public">

                <Link to="/files/public">
                  <Icon style={{ fontSize: 20 }} type="global" theme="outlined" />
                  <span>Public documents</span>
                </Link>

              </Menu.Item>
            </MenuItemGroup>

            {
              [2].indexOf(decoded.user_type) > -1 ? (
                <MenuItemGroup key="admin-group" className="gx-menu-group" title={'Administration'}>
                  <Menu.Item key="control/reminders">

                    <Link to="/control/reminders">
                      <Icon style={{ fontSize: 20 }} type="bell" theme="outlined" />
                      <span>Reminders</span>
                    </Link>

                  </Menu.Item>
                  <Menu.Item key="control/notices">

                    <Link to="/control/notices">
                      <Icon style={{ fontSize: 20 }} type="alert" theme="outlined" />
                      <span>Notices</span>
                    </Link>

                  </Menu.Item>
                  <Menu.Item key="control/permissions">

                    <Link to="/control/permissions">
                      <Icon style={{ fontSize: 20 }} type="file-protect" theme="outlined" />
                      <span>Client permissions</span>
                    </Link>

                  </Menu.Item>
                  <Menu.Item key="control/groupPermissions">

                    <Link to="/control/groupPermissions">
                      <Icon style={{ fontSize: 20 }} type="cluster" theme="outlined" />
                      <span>Group permissions</span>
                    </Link>

                  </Menu.Item>
                  <Menu.Item key="control/spaces">

                    <Link to="/control/spaces">
                      <Icon style={{ fontSize: 20 }} type="database" theme="outlined" />
                      <span>Manage spaces</span>

                    </Link>

                  </Menu.Item>
                </MenuItemGroup>
              ) : null
            }

            {
              [2, 4].indexOf(decoded.user_type) > -1 ? (
                <MenuItemGroup key="settings-group" className="gx-menu-group" title={'Settings'}>
                  {
                    [2].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="settings/firm">

                        <Link to="/settings/firm">
                          <Icon style={{ fontSize: 20 }} type="bank" theme="outlined" />
                          <span>My firm</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                  {
                    [2, 4].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="settings/auth">

                        <Link to="/settings/auth">
                          <Icon style={{ fontSize: 20 }} type="property-safety" theme="outlined" />
                          <span>Authentication</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                  {
                    [2].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="settings/notifications">

                        <Link to="/settings/notifications">
                          <Icon style={{ fontSize: 20 }} type="sound" theme="outlined" />
                          <span>Notification preferences</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                  {
                    [2].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="settings/configurations">

                        <Link to="/settings/configurations">
                          <Icon style={{ fontSize: 20 }} type="audit" theme="outlined" />
                          <span>Configurations</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                  {
                    [2].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="settings/subscription">

                        <Link to="/settings/subscription">
                          <Icon style={{ fontSize: 20 }} type="transaction" theme="outlined" />
                          <span>My Subscription</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                  {
                    [2].indexOf(decoded.user_type) > -1 ? (
                      <Menu.Item key="settings/bin">

                        <Link to="/settings/bin">
                          <Icon style={{ fontSize: 20 }} type="file-zip" theme="outlined" />
                          <span>Archieve</span>
                        </Link>

                      </Menu.Item>
                    ) : null
                  }
                </MenuItemGroup>
              ) : null
            }
          </Menu>
        </CustomScrollbars>
      </div>
    </Auxiliary >
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { authUser, token } = auth;
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname, authUser, token }
};
export default connect(mapStateToProps, { userSignOut })(SidebarContent);

