import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";


const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home/dashboard`} component={asyncComponent(() => import('./home/Dashboard'))} />
      <Route path={`${match.url}home/clientDashboard`} component={asyncComponent(() => import('./home/ClientDashboard'))} />
      <Route path={`${match.url}home/activity`} component={asyncComponent(() => import('./home/Activity'))} />
      <Route path={`${match.url}home/mfa`} component={asyncComponent(() => import('./home/StaffMfa'))} />

      <Route path={`${match.url}files/private`} component={asyncComponent(() => import('./files/PrivateFiles'))} />
      <Route path={`${match.url}files/staff`} component={asyncComponent(() => import('./files/FirmFiles'))} />
      <Route path={`${match.url}files/client`} component={asyncComponent(() => import('./files/ClientFiles'))} />
      <Route path={`${match.url}files/group`} component={asyncComponent(() => import('./files/GroupFiles'))} />
      <Route path={`${match.url}files/public`} component={asyncComponent(() => import('./files/PublicFiles'))} />
      <Route path={`${match.url}files/downloads`} component={asyncComponent(() => import('./files/Downloads'))} />

      <Route path={`${match.url}users/staffList`} component={asyncComponent(() => import('./users/StaffList'))} />
      <Route path={`${match.url}users/clientList`} component={asyncComponent(() => import('./users/ClientList'))} />
      <Route path={`${match.url}users/groupList`} component={asyncComponent(() => import('./users/GroupList'))} />

      <Route path={`${match.url}control/reminders`} component={asyncComponent(() => import('./control/Reminders'))} />
      <Route path={`${match.url}control/notices`} component={asyncComponent(() => import('./control/Notices'))} />
      <Route path={`${match.url}control/permissions`} component={asyncComponent(() => import('./control/Permissions'))} />
      <Route path={`${match.url}control/groupPermissions`} component={asyncComponent(() => import('./control/GroupPermissions'))} />
      <Route path={`${match.url}control/spaces`} component={asyncComponent(() => import('./control/Spaces'))} />

      <Route path={`${match.url}settings/firm`} component={asyncComponent(() => import('./settings/MyFirm'))} />
      <Route path={`${match.url}settings/auth`} component={asyncComponent(() => import('./settings/Authentication'))} />
      <Route path={`${match.url}settings/notifications`} component={asyncComponent(() => import('./settings/NotificationPrefs'))} />
      <Route path={`${match.url}settings/configurations`} component={asyncComponent(() => import('./settings/Configurations'))} />
      <Route path={`${match.url}settings/subscription`} component={asyncComponent(() => import('./settings/Subscription'))} />
      <Route path={`${match.url}settings/bin`} component={asyncComponent(() => import('./files/DeletedFiles'))} />
    </Switch>
  </div>
);

export default App;
