import React, { Component } from "react";
import {
  SET_LATEST_VERSION
} from "../../constants/ActionTypes";
import { connect } from "react-redux";
import { Avatar, Modal, Select } from "antd";
import axios from '../../util/Api';
import { switchFirm, setLatestVersion } from "../../appRedux/actions/Auth";

const _ = require('lodash');

const { Option } = Select;

class UserProfile extends Component {

  state = {
    data: [],
    loading: true,
    switching: false,
    firmName: null
  }

  componentDidMount() {
    this.loadFirms();
  }

  switchFirm(id) {
    this.setState({
      switching: true
    }, async () => {
      var firm = _.find(this.state.data, { id });
      this.setState({
        firmName: firm.firm_name
      }, () => {
        this.props.switchFirm({ firm_id: id });
      })
    })
  }

  loadFirms() {
    this.setState({
      loading: true
    }, async () => {

      try {
        var response = await axios.post('/v1/auth/me', {});
        if (response && response.data && response.data.success) {
          this.setState({
            loading: false,
            data: _.get(response, 'data.data.firms', [])
          }, () => {
            this.props.setLatestVersion(_.get(response, 'data.data.appVersion', null));
          });
        }
      } catch (error) {
        this.setState({ loading: false });
        console.log({ error });
      }
    })
  }

  renderFirmList() {
    if (!this.state.loading && _.isArray(this.state.data) && this.state.data.length > 1) {
      return (
        <ul className="gx-user-popover">
          {
            this.state.data.map((obj, i) => {
              return (
                <li onClick={() => this.switchFirm(obj.id, obj.firm_name)}>{obj.firm_name}</li>
              )
            })
          }
        </ul>
      )
    }
    return null;
  }

  render() {
    const { authUser, loading } = this.props;

    return (
      <>
        <div className="gx-flex-row gx-align-items-center gx-mb-1 gx-avatar-row">
          <Select
            disabled={!_.isArray(this.state.data) || this.state.data.length < 2}
            dropdownMatchSelectWidth={false}
            defaultValue={authUser.firm_name}
            loading={this.state.loading || loading}
            style={{ width: '100%' }}
            dropdownMenuStyle={{ backgroundColor: 'transparent' }}
            onChange={(value, option) => {
              this.switchFirm(value);
            }}
          >
            {
              this.state.data.map((obj, i) => {
                return (
                  <Option key={obj.id}>{obj.firm_name}</Option>
                )
              })
            }
          </Select>
        </div>
        <Modal
          visible={loading}
          title="Processing..."
          footer={[]}>
          <p>Please wait for a moment...</p>
        </Modal>
      </>
    )

  }
}

const mapStateToProps = ({ auth, commonData }) => {
  const { authUser } = auth;
  const { loading } = commonData;
  return { authUser, loading }
};

export default connect(mapStateToProps, { switchFirm, setLatestVersion })(UserProfile);
