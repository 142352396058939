import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
const jwtDecode = require('jwt-decode');
const MenuItemGroup = Menu.ItemGroup;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const decoded = jwtDecode(this.props.token);

    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">
        {
          [2, 4].indexOf(decoded.user_type) > -1 ? (
            <SubMenu key="home-group" className="gx-menu-group" title={'Home'}>
              {
                [2].indexOf(decoded.user_type) > -1 ? (
                  <Menu.Item key="home/dashboard">
                    <Link to="/home/dashboard">
                      <Icon style={{ fontSize: 20 }} style={{ fontSize: 20 }} type="dashboard" theme="outlined" />
                      <span>Dashboard</span>
                    </Link>
                  </Menu.Item>
                ) : (
                    <Menu.Item key="home/clientDashboard">
                      <Link to="/home/clientDashboard">
                        <Icon style={{ fontSize: 20 }} style={{ fontSize: 20 }} type="dashboard" theme="outlined" />
                        <span>Dashboard</span>
                      </Link>
                    </Menu.Item>
                  )
              }

            </SubMenu>
          ) : null
        }

        {
          [2, 3].indexOf(decoded.user_type) > -1 ? (
            <SubMenu key="users-group" className="gx-menu-group" title={'Users'}>
              <Menu.Item key="users/staffList">
                <Link to="/users/staffList">
                  <Icon style={{ fontSize: 20 }} type="user" theme="outlined" />
                  <span>Staff</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="users/clientList">
                <Link to="/users/clientList">
                  <Icon style={{ fontSize: 20 }} type="solution" theme="outlined" />
                  <span>Clients</span>

                </Link>
              </Menu.Item>
              <Menu.Item key="users/groupList">
                <Link to="/users/groupList">
                  <Icon style={{ fontSize: 20 }} type="team" theme="outlined" />
                  <span>Groups</span>

                </Link>
              </Menu.Item>
            </SubMenu>
          ) : null
        }

        <SubMenu key="files-group" className="gx-menu-group" title={'Documents'}>
          <Menu.Item key="files/private">
            <Link to="/files/private">
              <Icon style={{ fontSize: 20 }} type="safety-certificate" theme="outlined" />
              <span>Private documents</span>

            </Link>
          </Menu.Item>
          {
            [2, 3].indexOf(decoded.user_type) > -1 ? (
              <Menu.Item key="files/staff">
                <Link to="/files/staff">
                  <Icon style={{ fontSize: 20 }} type="hdd" theme="outlined" />
                  <span>Firm documents</span>

                </Link>
              </Menu.Item>
            ) : null
          }

          {
            [2, 3].indexOf(decoded.user_type) > -1 ? (
              <Menu.Item key="files/client">
                <Link to="/files/client">
                  <Icon style={{ fontSize: 20 }} type="file-done" theme="outlined" />
                  <span>Client documents</span>

                </Link>
              </Menu.Item>
            ) : null
          }

          <Menu.Item key="files/group">
            <Link to="/files/group">
              <Icon style={{ fontSize: 20 }} type="cluster" theme="outlined" />
              <span>Group documents</span>

            </Link>
          </Menu.Item>

          <Menu.Item key="files/public">
            <Link to="/files/public">
              <Icon style={{ fontSize: 20 }} type="global" theme="outlined" />
              <span>Public documents</span>

            </Link>
          </Menu.Item>
        </SubMenu>

        {
          [2].indexOf(decoded.user_type) > -1 ? (
            <SubMenu key="admin-group" className="gx-menu-group" title={'Administration'}>
              <Menu.Item key="control/reminders">
                <Link to="/control/reminders">
                  <Icon style={{ fontSize: 20 }} type="bell" theme="outlined" />
                  <span>Reminders</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="control/notices">
                <Link to="/control/notices">
                  <Icon style={{ fontSize: 20 }} type="alert" theme="outlined" />
                  <span>Notices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="control/permissions">
                <Link to="/control/permissions">
                  <Icon style={{ fontSize: 20 }} type="file-protect" theme="outlined" />
                  <span>Client permissions</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="control/groupPermissions">
                <Link to="/control/groupPermissions">
                  <Icon style={{ fontSize: 20 }} type="cluster" theme="outlined" />
                  <span>Group permissions</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="control/spaces">
                <Link to="/control/spaces">
                  <Icon style={{ fontSize: 20 }} type="database" theme="outlined" />
                  <span>Manage spaces</span>

                </Link>
              </Menu.Item>
            </SubMenu>
          ) : null
        }

        {
          [2].indexOf(decoded.user_type) > -1 ? (
            <SubMenu key="settings-group" className="gx-menu-group" title={'Settings'}>
              <Menu.Item key="settings/firm">
                <Link to="/settings/firm">
                  <Icon style={{ fontSize: 20 }} type="bank" theme="outlined" />
                  <span>My firm</span>

                </Link>
              </Menu.Item>
              <Menu.Item key="settings/auth">
                <Link to="/settings/auth">
                  <Icon style={{ fontSize: 20 }} type="property-safety" theme="outlined" />
                  <span>Authentication</span>

                </Link>
              </Menu.Item>
              <Menu.Item key="settings/notifications">
                <Link to="/settings/notifications">
                  <Icon style={{ fontSize: 20 }} type="sound" theme="outlined" />
                  <span>Notification preferences</span>

                </Link>
              </Menu.Item>
              {/* <Menu.Item key="templates">
                    <Link to="/templates">
                      <Icon style={{ fontSize: 20 }} type="profile" theme="outlined" />
                      <span>Templates</span>

                    </Link>
                  </Menu.Item> */}
              <Menu.Item key="settings/bin">
                <Link to="/settings/bin">
                  <Icon style={{ fontSize: 20 }} type="delete" theme="outlined" />
                  <span>Trash</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          ) : null
        }
      </Menu>

    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { authUser, token } = auth;
  const { themeType, navStyle, pathname, locale } = settings;
  return { themeType, navStyle, pathname, locale, authUser, token }
};
export default connect(mapStateToProps)(HorizontalNav);

