import React from "react";
import { Button, Checkbox, Form, Input, message, Modal } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import axios from './../util/Api'

const _ = require('lodash');

const FormItem = Form.Item;

class ResetPassword extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.changePassword(values);
      }
    });
  };

  changePassword = async ({ email, password, new_password }) => {
    try {
      var { data } = await axios.post('v1/auth/password/change', {
        email, password, new_password
      });
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        Modal.info({
          title: 'Password has been changed successfully',
          content: (
            <div>
              <p>You may now login with your new password</p>
            </div>
          ),
          onOk: (() => {
            this.props.history.push('/signin');
            window.location.reload();
          }),
        });
      } else {
        message.error(_.get(data, 'data.error', 'Something went wrong!'));
      }
    }
    catch (e) {
      message.error(_.get(e, 'response.data.data.message', null));
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={require("assets/images/bg_image.png")} alt='Neature' />
              </div>
              <div className="gx-app-logo-wid">
                <h1>Reset Password</h1>
                <p>Reset your Quick Client Portal password.</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{ required: true, message: 'Please input current password' }],
                  })(
                    <Input type="password" placeholder="Current Password" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('new_password', {
                    initialValue: "",
                    rules: [{ required: true, message: 'Please input new password' }],
                  })(
                    <Input type="password" placeholder="New Password" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('confirm_password', {
                    initialValue: "",
                    rules: [{ required: true, message: 'Please re-enter the new password' }],
                  })(
                    <Input type="password" placeholder="Confirm Password" />
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Proceed
                  </Button>
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(ResetPassword);

const mapStateToProps = ({ auth }) => {
  const { token, otpRequired } = auth;
  return { token, otpRequired }
};

export default connect(mapStateToProps,  null)(WrappedNormalLoginForm);
