import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Tag, Row, Col } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
const _ = require('lodash');

class UserInfo extends Component {

  getRole() {
    const roles = {
      2: 'Admin',
      3: 'Staff Member',
      4: 'Client'
    };
    return _.defaultTo(roles[this.props.authUser.user_type], 'User');
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">

        {
          _.get(this.props, 'authUser.first_name', null) ? (
            <li className="gx-language">
              <span className="gx-pointer gx-flex-row">
                <Row className="gx-mt-1">
                  <Col span={8}>
                    <Avatar style={{ backgroundColor: '#002244', verticalAlign: 'middle' }} size="large">{this.props.authUser.first_name.charAt(0)}</Avatar>
                  </Col>
                  <Col span={16}>
                    <Row>
                      <span className="gx-pl-2 gx-language-name">{this.props.authUser.first_name} {this.props.authUser.last_name}</span>
                    </Row>
                    <Row>
                      <Tag
                        className="gx-ml-2 gx-mt-1"
                        color={'green'}>
                        {this.getRole()}
                      </Tag>
                    </Row>
                  </Col>
                </Row>
              </span>
            </li>
          ) : (
              <Avatar src='https://via.placeholder.com/150x150'
                className="gx-avatar gx-pointer" alt="" />
            )
        }

      </Popover>
    )

  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { authUser } = auth;
  return { authUser }
};

export default connect(mapStateToProps, { userSignOut })(UserInfo);

